<template>
  <div>
    <van-search v-model="searchValue" placeholder="设备序列号" @search="onSearch" @cancel="onCancel" />
    <van-list v-model="listLoading" :finished="listFinished" finished-text="没有更多了" @load="getList">
      <div v-for="(item,index) in listData" :key="index">
        <van-cell :title="item.serialNumber+'('+item.deviceType+')'" is-link :value="item.expiresDate" @click="onShowCal(index)"></van-cell>
      </div>
    </van-list>
    <van-calendar v-model="showCal" @confirm="onConfirmCal" :default-date="defaultValue" title="选择过期日期" :min-date="minDate" :max-date="maxDate" />
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
import Moment from "moment";
Vue.use(Vant);
export default {
  data() {
    return {
      minDate: new Moment("2022-09-14").toDate(),
      maxDate: new Moment("2030-09-14").toDate(),
      searchValue: "",
      pageSize: 10,
      currentPage: 1,
      listLoading: false,
      listFinished: false,
      listData: [],
      showCal: false,
      editIndex: -1,
      defaultValue: new Date(),
    };
  },
  methods: {
    onShowCal(index) {
      let that = this;
      that.editIndex = index;
      that.defaultValue = new Moment(
        that.listData[that.editIndex].expiresDate
      ).toDate();
      this.showCal = true;
    },
    onConfirmCal(v) {
      let that = this;
      that.showCal = false;
      that.listData[that.editIndex].expiresDate = new Moment(v).format(
        "yyyy-MM-DD HH:mm:ss"
      );
      that.axios
        .post("Base_Device/UpdateModel", this.listData[this.editIndex])
        .then(function () {
          that.$notify({ type: "success", message: "保存成功" });
        })
        .catch(function () {
          that.$notify({ type: "warning", message: "保存失败" });
        });
    },
    onCancel() {},
    onSearch() {
      let that = this;
      that.listLoading = that.listFinished = false;
      that.listData = [];
      that.currentPage = 1;
      that.listLoading = true;
      that.getList();
    },
    getList() {
      let that = this;
      that.axios
        .post("Base_Device/GetList", {
          serialNumber: that.searchValue,
          pageSize: that.pageSize,
          currentPage: that.currentPage,
        })
        .then(function (response) {
          console.log(response);
          response.data.data.data.forEach((element) => {
            that.listData.push(element);
          });
          that.listLoading = false;
          if (that.listData.length >= response.data.data.total) {
            that.listFinished = true;
          } else {
            that.currentPage++;
          }
        });
    },
  },
  mounted() {},
};
</script>

<style>
</style>